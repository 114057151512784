export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};
// AppConfig
export interface AppConfig {
    pages: ConfigPages;
    validations: {
        required?: string;
        number?: string;
        minlength?: string;
        maxlength?: string;
        min?: string;
        max?: string;
        email?: string;
        default?: string;
    };
    menu: Menu[];
    mobileMenu?: Menu[];
    footerMenu: FooterMenu[];
    swiperBanner?: {desktop: string, mobile: string}[];
    swiperBannerSuccess?: {desktop: string, mobile: string}[];
}

export interface ConfigPages {
    login: ConfigAuthPage;
    register: ConfigAuthPage;
}

export class Menu {
    id!: number;
    path!: string;
    title!: string;
    external?: boolean;
    target?: '_blank' | '_self' | '_parent' | '_top';
    icon?: string;
    iconType?: 'icon' | 'img';
    submenu?: Menu[];
    custom?: boolean;

    constructor(
        id: number,
        path: string,
        title: string,
        external: boolean = false,
        target: '_blank' | '_self' | '_parent' | '_top' = '_self',
        icon?: string,
        iconType: 'icon' | 'img' = 'icon',
        submenu?: Menu[],
        custom?: boolean
    ) {
        this.id = id;
        this.path = path;
        this.title = title;
        this.external = external;
        this.target = target;
        this.icon = icon;
        this.iconType = iconType;
        this.submenu = submenu;
        this.custom = custom;
    }

    static create(data: Partial<Menu>): Menu {
        return new Menu(
            data.id!,
            data.path!,
            data.title!,
            data.external ?? false,
            data.target ?? '_self',
            data.icon,
            data.iconType ?? 'icon',
            data.submenu,
            data.custom
        );
    }
}

export class FooterMenu {
    id!: number;
    title!: string;
    menu!: Menu[];
}

export interface ConfigAuthPage {
    title: string;
    subtitle?: string;
    form: {
        name?: Partial<ConfigFormField>;
        email?: Partial<ConfigFormField>;
        phone?: Partial<ConfigFormField>;
        city?: Partial<ConfigFormField>;
        credential?: Partial<ConfigFormField>;
        password?: Partial<ConfigFormField>;
        repeatPassword?: Partial<ConfigFormField>;
        rememberMe?: Partial<ConfigFormField>;
    };
    forgotPassword?: string;
    submit: string;
    nextAuth?: {
        question: string;
        action: string;
    }
}
export interface ConfigFormField {
    label: string;
    placeholder: string;
    action: string;
}

export interface ConfigFormFieldValidation {
    required: string;
    invalid: string;
}
